var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ContextTitle", {
        attrs: { passedActions: _vm.titleBarActions, title: "Users" },
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "12" } },
            [
              _c(
                "v-row",
                {
                  staticClass: "action-row mt-0",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.onAdd.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "user-add-logo" }),
                  _c("div", { staticClass: "action-row__title" }, [
                    _vm._v("Create User"),
                  ]),
                ]
              ),
              _vm.users.length
                ? _c("v-data-table", {
                    staticClass: "users-table",
                    attrs: {
                      items: _vm.users,
                      headers: _vm.headers,
                      expand: _vm.expand,
                      "item-key": "id",
                      "hide-default-header": "",
                      "hide-default-footer": "",
                      "disable-pagination": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "no-data",
                          fn: function () {
                            return [_c("div", { attrs: { id: "no-data" } })]
                          },
                          proxy: true,
                        },
                        {
                          key: "item",
                          fn: function ({ expand, isExpanded, item }) {
                            return [
                              _c(
                                "tr",
                                {
                                  on: {
                                    click: function ($event) {
                                      return expand(!isExpanded)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "v-row",
                                        { staticClass: "pt-1 pl-5" },
                                        [
                                          _c("div", {
                                            staticClass: "row__icon",
                                            class: {
                                              "row__icon--active": isExpanded,
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "row__title" },
                                            [_vm._v(_vm._s(item.name))]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("td", [
                                    _vm._v(_vm._s(item.partnerShortCode)),
                                  ]),
                                  _c("td"),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "expanded-item",
                          fn: function ({ item }) {
                            return [
                              _c("tr", [
                                _c(
                                  "td",
                                  { attrs: { colspan: "3" } },
                                  [
                                    _c(
                                      "v-row",
                                      { staticClass: "expand-row pt-3 mr-0" },
                                      [
                                        _c("v-col", { staticClass: "ml-5" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "expand-section-title",
                                            },
                                            [_vm._v("CONTACT INFO")]
                                          ),
                                          _c("div", { staticClass: "email" }, [
                                            _vm._v(_vm._s(item.email)),
                                          ]),
                                          _c("div", { staticClass: "phone" }, [
                                            _vm._v(_vm._s(item.phone)),
                                          ]),
                                        ]),
                                        !item.deliveryPartner
                                          ? _c(
                                              "v-col",
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "expand-section-title",
                                                  },
                                                  [_vm._v("STORE ACCESS")]
                                                ),
                                                _vm._l(
                                                  item.storeAccess,
                                                  function (store) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: store.storeWaypointID,
                                                        staticClass: "store",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              `${store.storeName} (${store.airportIdent})`
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            )
                                          : _c("v-col", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "expand-section-title",
                                                },
                                                [_vm._v("DELIVERY PARTNER")]
                                              ),
                                            ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      {
                                        staticClass:
                                          "expand-actions pb-2 mr-0 ml-5",
                                      },
                                      [
                                        _c(
                                          "ActionButton",
                                          {
                                            attrs: {
                                              type: "green",
                                              action: () => {
                                                _vm.onEdit(item)
                                              },
                                              noIcon: true,
                                            },
                                          },
                                          [_vm._v("Edit ")]
                                        ),
                                        _c(
                                          "ActionButton",
                                          {
                                            attrs: {
                                              type: "green",
                                              action: () => {
                                                _vm.onDuplicate(item)
                                              },
                                              noIcon: true,
                                            },
                                          },
                                          [_vm._v("Duplicate ")]
                                        ),
                                        _c(
                                          "ActionButton",
                                          {
                                            attrs: {
                                              type: "red",
                                              action: () => {
                                                _vm.onDelete(item)
                                              },
                                              noIcon: true,
                                            },
                                          },
                                          [_vm._v("Delete ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4237621238
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.deleteConfirmationModalID
        ? _c(
            "EditingModal",
            { attrs: { size: "sm" } },
            [
              _c(
                "AlertDialog",
                {
                  attrs: {
                    title: "Confirm Delete",
                    onExit: () => (_vm.deleteConfirmationModalID = false),
                    actions: _vm.deleteConfirmationActions,
                  },
                },
                [_vm._v("Are you sure you want to delete this user? ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }