<template>
    <div>
        <ContextTitle :passedActions="titleBarActions" title="Users"/>
        <v-row>
            <v-col md="12">
                <v-row class="action-row mt-0" @click.stop="onAdd">
                    <div class="user-add-logo"/>
                    <div class="action-row__title">Create User</div>
                </v-row>
                <v-data-table
                    v-if="users.length"
                    class="users-table"
                    :items="users"
                    :headers="headers"
                    :expand="expand"
                    item-key="id"
                    hide-default-header
                    hide-default-footer
                    disable-pagination
                >
                    <template v-slot:no-data>
                        <div id="no-data"/>
                    </template>
                    <template v-slot:item="{expand, isExpanded, item}">
                        <tr @click="expand(!isExpanded)">
                            <td>
                                <v-row class="pt-1 pl-5">
                                    <div
                                        class="row__icon"
                                        :class="{ 'row__icon--active': isExpanded }"
                                    ></div>
                                    <div class="row__title">{{ item.name }}</div>
                                </v-row>
                            </td>
                            <td>{{ item.partnerShortCode }}</td>
                            <td/>
                        </tr>
                    </template>
                    <template v-slot:expanded-item="{item}">
                        <tr>
                            <td colspan="3">
                                <v-row class="expand-row pt-3 mr-0">
                                    <v-col class="ml-5">
                                        <div class="expand-section-title">CONTACT INFO</div>
                                        <div class="email">{{ item.email }}</div>
                                        <div class="phone">{{ item.phone }}</div>
                                    </v-col>
                                    <v-col v-if="!item.deliveryPartner">
                                        <div class="expand-section-title">STORE ACCESS</div>
                                        <div
                                            class="store"
                                            v-for="store in item.storeAccess"
                                            :key="store.storeWaypointID"
                                        >
                                            {{ `${store.storeName} (${store.airportIdent})` }}
                                        </div>
                                    </v-col>
                                    <v-col v-else>
                                        <div class="expand-section-title">DELIVERY PARTNER</div>
                                    </v-col>
                                </v-row>
                                <v-row class="expand-actions pb-2 mr-0 ml-5">
                                    <ActionButton
                                        type="green"
                                        :action="
                                          () => {
                                            onEdit(item);
                                          }
                                        "
                                        :noIcon="true"
                                    >Edit
                                    </ActionButton>
                                    <ActionButton
                                        type="green"
                                        :action="
                                          () => {
                                            onDuplicate(item);
                                          }
                                        "
                                        :noIcon="true"
                                    >Duplicate
                                    </ActionButton
                                    >
                                    <ActionButton
                                        type="red"
                                        :action="
                                          () => {
                                            onDelete(item);
                                          }
                                        "
                                        :noIcon="true"
                                    >Delete
                                    </ActionButton>
                                </v-row>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <EditingModal v-if="deleteConfirmationModalID" size="sm">
            <AlertDialog
                title="Confirm Delete"
                :onExit="() => (deleteConfirmationModalID = false)"
                :actions="deleteConfirmationActions"
            >Are you sure you want to delete this user?
            </AlertDialog
            >
        </EditingModal>
    </div>
</template>

<script>
import ContextTitle from "components/context_title.vue";
import EditingModal from "components/editing_modal.vue";
import AlertDialog from "components/alert_dialog.vue";
import { mapGetters, mapActions } from "vuex";
import ActionButton from "components/action_button.vue";

export default {
    name: "UsersIndex",
    data() {
        return {
            deleteConfirmationModalID: false,
            deleteConfirmationActions: [
                {
                    type: "cancel",
                    display: "Cancel",
                    run: () => {
                        this.deleteConfirmationModalID = false;
                    },
                },
                {
                    type: "remove-red",
                    display: "Yes, delete",
                    run: this.deleteItem,
                },
            ],
            headersLength: 2,
            titleBarActions: [],
            rowsPerPageItems: [15, 25, 35, 40, 100],
            pagination: {
                rowsPerPage: 15,
                page: 1,
            },
            expand: false,
            headers: [
                {
                    sortable: false,
                    text: "",
                    value: "name",
                },
                {
                    sortable: false,
                    text: "",
                    value: "shortCode",
                },
                {
                    sortable: false,
                    text: "",
                    value: "spacing",
                },
            ],
        };
    },
    components: {
        ContextTitle,
        EditingModal,
        AlertDialog,
        ActionButton,
    },
    computed: {
        users() {
            return this.users;
        },
        ...mapGetters("UserStore", ["users"]),
    },
    mounted() {
        this.getUsers();
    },
    methods: {
        deleteItem() {
            this.deleteUser(this.deleteConfirmationModalID).then(() => {
                this.deleteConfirmationModalID = false;
            });
        },
        onAdd() {
            this.$router.push({ name: "user-create" });
        },
        onEdit(user) {
            this.$router.push({ name: "user-edit", params: { userID: user.id } });
        },
        onDelete(user) {
            this.deleteConfirmationModalID = user.id;
        },
        onDuplicate(user) {
            this.setDuplicatedStoreAccess(user.storeAccess);
            this.$router.push({ name: "user-create" });
        },
        ...mapActions("UserStore", [
            "getUsers",
            "deleteUser",
            "setDuplicatedStoreAccess",
        ]),
    },
};
</script>

<style lang="scss" scoped>
@import "~scss/variables";
@import "~scss/mixins";

.expand-actions {
  border-top: 1px solid rgba(0, 0, 0, 0.05) !important;
}
#no-data {
  height: 100%;
  width: 100%;
}

.v-data-table {
  font-size: 12px;
  tbody {
    tr:hover:not(.v-datatable__expand-row) {
      background: white;
    }

    tr {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;

      td {
        color: #585858 !important;
        vertical-align: middle;
        height: 41px;
        font-size: 14px;
        padding-left: 30px;
        width: 250px;
      }
    }
  }
}

.row {
  &__title {
    padding-left: spacing(xs);
  }

  &__icon {
    @include background-image("", "user_normal.svg", "../../../img/");
    background-size: contain;
    width: spacing(sm);
    height: spacing(sm);

    &--active {
      @include background-image("", "user_open.svg", "../../../img/");
    }
  }

  &--delete {
    @include background-image("", "delete.svg", "../../../img/");
    background-size: 20px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-top: -2px;
  }
  &--edit {
    @include background-image("", "edit_logo.svg", "../../../img/");
    background-size: 14px;
    cursor: pointer;

    margin-left: 15px;
    width: 20px;
    height: 20px;
    padding: 5px;
  }
}

.action-row {
  padding: 15px;
  cursor: pointer;
  align-items: center;
  &__title {
    margin-left: 10px;
    color: $brand_color !important;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
  }
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  margin-right: 0px !important;
  margin-left: 0px !important;
  padding-left: 4px !important;
}
.user-add-logo {
  @include background-image("", "user_add.svg", "../../../img/");
  margin-left: 15px;
  flex-grow: 0 !important;
  width: spacing(sm);
  height: spacing(sm);
  background-size: contain;
}
.expand-section-title {
  height: 14px;
  width: 174px;
  color: #555555;
  font-family: Roboto;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 5px;
}
.expand-row {
  color: #555555;
  font-family: Roboto;
  font-size: 14px;
  line-height: 20px;

  .email,
  .store {
    color: $brand_color;
  }
}
</style>
